<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_105_24)">
      <path
        d="M8 63.3846C8 165.345 90.6551 248 192.615 248H220.308C235.602 248 248 235.602 248 220.308V203.426C248 197.073 243.676 191.534 237.512 189.993L183.073 176.384C177.668 175.032 171.98 177.052 168.638 181.509L156.697 197.43C153.231 202.051 147.237 204.094 141.813 202.108C101.113 187.198 68.8019 154.887 53.8925 114.187C51.9057 108.763 53.949 102.769 58.5699 99.3034L74.4914 87.3623C78.9481 84.0197 80.9675 78.3317 79.6164 72.9272L66.0066 18.488C64.4656 12.3241 58.9274 8 52.5739 8L35.6923 8C20.3983 8 8 20.3983 8 35.6923L8 63.3846Z"
        stroke="currentColor"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_24">
        <rect
          width="256"
          height="256"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
